export const LOGIN = "LOGIN";
export const FETCH_DISPUTES = "FETCH_DISPUTES";
export const SET_DISPUTES_ERROR = "SET_DISPUTES_ERROR";
export const FETCH_DISPUTE_STATS = "FETCH_DISPUTE_STATS";
export const FETCH_ARGUMENTS = "FETCH_ARGUMENTS";
export const CLOSE_CASE = "CLOSE_CASE";
export const REQUEST_MEDIATOR = "REQUEST_MEDIATOR";
export const APPEAL_CASE = "APPEAL_CASE";
export const SET_LOADING = "SET_LOADING";
export const ADD_ARGUMENT = "ADD_ARGUMENT";
export const SET_ARGUMENT_DELIVERED = "SET_ARGUMENT_DELIVERED";
export const LOGOUT = "LOGOUT";
