import moment from "moment";

export function formatDate(d) {
  return moment(d).calendar(null, {
    sameDay: "[Today] h:mm a",
    lastDay: "[Yesterday]",
    lastWeek: "ddd MMM Do YYYY, h:mm a",
    sameElse: "ddd MMM Do YYYY, h:mm a",
  });
}
