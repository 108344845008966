import React, { useEffect } from "react";
import { HOME } from "../../constants/routeNames";
import { connect } from "react-redux";

import { logout } from "../../redux/actions";

function Logout({ user, history, logout }) {
  useEffect(() => {
    logout();

    history.push(HOME);
  }, [history, logout]);

  return <div>LOADING...</div>;
}

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default connect(mapStateToProps, { logout })(Logout);
