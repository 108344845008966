import React from "react";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";

export default class MessageField extends React.Component {
  constructor(props) {
    super(props);
    this.sendChat = props.sendChat;
    this.state = { editorState: EditorState.createEmpty(), text: "" };
    this.messageFieldRef = React.createRef();
  }

  onChange = (editorState) => {
    const text = editorState.getCurrentContent().getPlainText();
    this.setState({ ...this.state, editorState, text });
  };

  handleClick = (e) => {
    e.preventDefault();
    this.sendChat(this.state.text);

    // empty up the text editor for new input after the user submits a message
    this.setState({ ...this.state, editorState: EditorState.createEmpty() });

    // focus the text editor after the user submits a message
    setTimeout(() => {
      this.messageFieldRef.current.editor.focus();
    }, 100);
  };

  render() {
    return (
      <form className="message-box">
        <Editor
          placeholder="Start typing here..."
          editorState={this.state.editorState}
          onChange={this.onChange}
          ref={this.messageFieldRef}
        />
        <button onClick={this.handleClick}>
          <i className="fas fa-paper-plane"></i>
        </button>
      </form>
    );
  }
}
