import axios from "axios";
import { BASE_URL } from "../constants";

const API_URL = BASE_URL + "/mobile";

const instance = axios.create({ baseURL: API_URL });

instance.interceptors.request.use((config) => {
  const token = window.localStorage.getItem("token");

  config.headers.channel = "MOBILE_WEB";

  if (token) {
    config.headers.Authorization = token;
  }

  return config;
});

export default instance;
