import {
  FETCH_DISPUTES,
  FETCH_DISPUTE_STATS,
  CLOSE_CASE,
  REQUEST_MEDIATOR,
  SET_LOADING,
  APPEAL_CASE,
  ADD_ARGUMENT,
  SET_ARGUMENT_DELIVERED,
  FETCH_ARGUMENTS,
  SET_DISPUTES_ERROR,
} from "../types";

const INITIAL_STATE = {
  all: null,
  stats: null,
  nav: null,
  loading: false,
  error: { disputes: null, stats: null },
};

export default function (state = INITIAL_STATE, { type, payload }) {
  switch (type) {
    case FETCH_DISPUTES: {
      const all = {};

      for (let dispute of payload.data) {
        all[dispute.id] = dispute;
      }

      return { ...state, all, nav: payload.nav };
    }

    case SET_DISPUTES_ERROR: {
      return { ...state, error: { ...state.error, disputes: payload } };
    }

    case FETCH_DISPUTE_STATS: {
      return { ...state, stats: payload };
    }

    case CLOSE_CASE: {
      return {
        ...state,
        stats: {
          ...state.stats,
          open_disputes: state.stats.open_disputes - 1,
          closed_disputes: state.stats.closed_disputes + 1,
        },
        all: {
          ...state.all,
          [payload]: { ...state.all[payload], status: "CLOSED" },
        },
      };
    }

    case REQUEST_MEDIATOR: {
      return {
        ...state,
        all: {
          ...state.all,
          [payload]: { ...state.all[payload], mediator_requested: true },
        },
      };
    }

    case APPEAL_CASE: {
      return {
        ...state,
        all: {
          ...state.all,
          [payload]: { ...state.all[payload], decision_reached: false },
        },
      };
    }
    case SET_LOADING:
      return { ...state, loading: payload };

    case ADD_ARGUMENT: {
      const { dispute_id: disputeId } = payload;
      const dispute = state.all[disputeId];

      delete payload.disputeId; // remove the property disputeId since the dispute already has a property id which is the same

      if (!dispute) return { ...state };

      return {
        ...state,
        all: {
          ...state.all,
          [disputeId]: {
            ...dispute,
            arguments: [...dispute.arguments, payload],
          },
        },
      };
    }

    case SET_ARGUMENT_DELIVERED: {
      const { disputeId, delivered, clientId } = payload;

      let dispute = state.all[disputeId];

      // this stops the reducer from kicking off a re-render if the dispute u are loooking for is not here
      if (!dispute) return { ...state };

      dispute.arguments = dispute.arguments.map((i) => {
        if (i.clientId === clientId) return { ...i, delivered };
        return { ...i };
      });

      return {
        ...state,
        all: {
          ...state.all,
          [payload.disputeId]: { ...dispute },
        },
      };
    }

    case FETCH_ARGUMENTS: {
      return { ...state };
    }

    default: {
      return state;
    }
  }
}
