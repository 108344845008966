import React, { Fragment } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
}) {
  return (
    <Fragment>
      <DatePicker
        onChange={onStartDateChange}
        closeOnScroll={true}
        selected={startDate}
        isClearable
        placeholderText="Filter starting from..."
      />

      <DatePicker
        onChange={onEndDateChange}
        closeOnScroll={true}
        selected={endDate}
        placeholderText="To..."
        isClearable
      />
    </Fragment>
  );
}
