import socketIOClient from "socket.io-client";

import api from "../api";
import { BASE_URL } from "../constants";

let socket;
const REQUEST_FAILED_MSG =
  "We are having trouble connecting, please check your internet connection if this issue persists.";

export function getSocketInstance() {
  var token = window.localStorage.getItem("token");

  if (!socket?.connected)
    socket = socketIOClient.connect(BASE_URL, {
      transportOptions: {
        polling: {
          extraHeaders: {
            Authorization: token,
            channel: "MOBILE_WEB",
          },
        },
      },
    });

  return socket;
}

function handleRequestFailed({ request, response }) {
  let msg = REQUEST_FAILED_MSG;

  if (request) msg = request?.responseText || msg;
  else if (response) msg = response?.data?.msg || msg;

  return { code: "01", msg };
}

async function _makeRequest(options) {
  let res = {};

  try {
    const { data } = await api(options);
    res = data;
  } catch (error) {
    res = handleRequestFailed(error);
  } finally {
    // this line of code is here because the actual message that users need in cases where something went wrong from the
    // request is sometimes hiddend under the error.description prop of the response form the server
    res.msg = res.error?.description || res.msg;

    return res;
  }
}

export async function makeRequest(options) {
  return _makeRequest(options);
}

export async function fetchDispute(disputeId) {
  return _makeRequest({
    url: `/disputes/${disputeId}/arguments`,
    method: "get",
  });
}

export function loginWithCredentials(data) {
  return _makeRequest({ url: "/auth/login-with-cred", method: "post", data });
}

export async function loginWithToken(data) {
  return _makeRequest({ url: "/auth/login", method: "post", data });
}

export async function requestOTPSignup(data) {
  return _makeRequest({
    url: "/auth/request-otp-signup",
    method: "post",
    data,
  });
}

export async function verifyOTP(data) {
  return _makeRequest({ url: "/auth/verify-otp", method: "post", data });
}

export async function setNewPIN(data) {
  return _makeRequest({ url: "/auth/set-password", method: "post", data });
}

export async function resetPin(data) {
  return _makeRequest({ url: "/auth/forgot-pin", method: "post", data });
}

export function persistAuthState(data) {
  localStorage.setItem("token", data.token);
}
