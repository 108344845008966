import { decodeToken } from "react-jwt";

import { LOGIN, LOGOUT } from "../types";

const INITIAL_STATE = { user: getUser() };

function getUser() {
  const token = localStorage.getItem("token");
  if (!token) return null;

  const { sub: user } = decodeToken(token);

  return user;
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN: {
      return { ...state, user: action.payload };
    }
    case LOGOUT: {
      return { ...state, user: getUser() };
    }
    default:
      return state;
  }
}
