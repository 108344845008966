import React, { useEffect, useState, useRef } from "react";
import { Redirect, Link } from "react-router-dom";
import { connect } from "react-redux";
import toast from "toasted-notes";
import classnames from "classnames";
import { decodeToken } from "react-jwt";

import { login } from "../../redux/actions";
import {
  loginWithCredentials,
  persistAuthState,
  loginWithToken,
} from "../../common";
import "../../auth.css";
import { HOME, SIGN_UP, FORGOT_PIN } from "../../constants/routeNames";

function Login({ login, user, location, history }) {
  const [phone, setPhone] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(false);
  const [fromMobileApp, setFromMobileApp] = useState(false);

  const search = new URLSearchParams(location.search);
  const msisdnRef = useRef(search.get("msisdn"));
  const tokenRef = useRef(search.get("token"));

  useEffect(() => {
    async function run() {
      const { current: msisdn } = msisdnRef;
      const { current: token } = tokenRef;

      if (!msisdn || !token) return;

      /**
       * if the code by passes the heck above, then the assumption is the request is from the webview thrown into the
       * mobile app, since its the only one allowed to make a request with these details
       */
      setFromMobileApp(true);
      const res = await loginWithToken({ msisdn, token });

      if (res.code !== "00") {
        login(false);
        return;
      }

      // this would later be used to determine if the dropdown menu should be shown
      localStorage.setItem("client", "MOBILE_APP");

      persistAuthState(res);
      const { sub: user } = decodeToken(res.token);

      login(user);
    }

    run();
  }, [login]);

  async function submitLoginForm(e) {
    e.preventDefault();

    if (!phone) return toast.notify("Please Enter Phone Number");

    if (!pin) return toast.notify("Pleas Enter PIN");

    if (isNaN(pin)) return toast.notify("PIN must be only numbers");

    if (pin.length !== 4) return toast.notify("PIN must not be only 4 numbers");

    setLoading(true);
    const res = await loginWithCredentials({ login_id: phone, password: pin });
    setLoading(false);

    if (res.code !== "00")
      return toast.notify(res.error?.description || res.msg);

    persistAuthState(res);
    const { sub: user } = decodeToken(res.token);

    login(user);
    history.push(HOME);
  }

  function renderContent() {
    if (fromMobileApp && user === null) {
      return (
        <div id="login" className="auth">
          <div>
            <i className="fas fa-spinner fa-spin"></i>
          </div>
        </div>
      );
    } else if (fromMobileApp && user === false) {
      return (
        <div id="login" className="auth">
          <div id="auth-failed">Authentication Failed</div>;
        </div>
      );
    } else if (user) {
      return <Redirect to={HOME} />;
    }

    return (
      <div id="login" className="auth">
        <form onSubmit={submitLoginForm}>
          <div className="welcome-texts">
            <p>Welcome to</p>
            <h3>Dispute Center</h3>
          </div>

          <div>
            <div className="input-fields">
              <div>
                <label>Phone Number</label>
                <i className="far fa-user-circle"></i>
                <input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value.trim())}
                  placeholder="Phone Number"
                />
              </div>

              <div>
                <label>PIN</label>
                <img src={require("../../assets/img/text.svg")} alt="" />
                <input
                  value={pin}
                  onChange={(e) => setPin(e.target.value.trim())}
                  placeholder="PIN"
                  type="password"
                />
              </div>
            </div>

            <button
              disabled={loading}
              className={classnames("submit", { loading })}
            ></button>

            <div className="alternate-links">
              <Link to={SIGN_UP}>sign up</Link>
              <Link to={FORGOT_PIN}>forgot pin?</Link>
            </div>
          </div>
        </form>
      </div>
    );
  }

  return renderContent();
}

function mapStateToProps(state) {
  return { user: state.auth.user };
}

export default connect(mapStateToProps, { login })(Login);
