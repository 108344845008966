import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { LOGIN } from "../constants/routeNames";

function ProtectedRoute({ component: Component, user, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return (
            <Redirect
              to={{
                pathname: LOGIN,
                state: { from: props.location },
              }}
            />
          );
        }

        return <Component {...props} />;
      }}
    />
  );
}

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default connect(mapStateToProps)(ProtectedRoute);
