import React from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";

function Card({ dispute, user }) {
  const history = useHistory();
  const { escrow } = dispute;
  const { recipient, sender, amount, description } = escrow;

  function renderTitle() {
    return recipient.id === user.id ? sender.name : recipient.name;
  }

  return (
    <div
      className="case-card"
      onClick={() => {
        history.push(`/chat/${dispute.id}`, { disputeId: dispute.id });
      }}
    >
      <div className="main details">
        <p>{renderTitle()}</p>
        <span className="amount">GHS {amount}</span>
      </div>

      <div>
        <div className="meta details">
          <p>{description}</p>
          <span>{escrow.payment_ref}</span>
        </div>
      </div>
    </div>
  );
}

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

export default connect(mapStateToProps, {})(Card);
