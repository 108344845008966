import React, { useState } from "react";
import { Link } from "react-router-dom";
import toast from "toasted-notes";
import classnames from "classnames";

import { resetPin } from "../../common";
import "../../auth.css";

export default function SignUp({ history }) {
  const [msisdn, setMsisdn] = useState("");
  const [loading, setLoading] = useState(false);

  async function submitForm(e) {
    e.preventDefault();

    if (!msisdn) return toast.notify("Please Enter Phone Number");

    setLoading(true);
    const res = await resetPin({ msisdn });
    setLoading(false);

    if (res.code !== "00") return toast.notify(res.msg);

    history.push(`/verify-otp/${msisdn}`);
  }

  return (
    <div id="forgot-pin" className="auth">
      <form onSubmit={submitForm}>
        <div className="welcome-texts">
          <h3>Dispute Center</h3>
          <p>An OTP will be sent to your number.</p>
        </div>

        <div>
          <div className="input-fields">
            <div>
              <label>Phone Number</label>
              <i className="far fa-user-circle"></i>
              <input
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value.trim())}
                placeholder="Enter Your Phone Number"
              />
            </div>
          </div>

          <button
            disabled={loading}
            className={classnames("submit", { loading })}
          ></button>

          <div className="alternate-links">
            <Link to="/">back to login</Link>
          </div>
        </div>
      </form>
    </div>
  );
}
