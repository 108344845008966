import React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import { formatDate } from "../../util";

function Argument({ argument, user }) {
  const sent = argument.user.id === user.id;

  function getName() {
    return argument.user.id === user.id ? "You" : argument.user.name;
  }

  function renderMessageIcon() {
    if (sent) {
      if (argument.delivered) {
        return <i className="fas fa-check"></i>;
      } else if (argument.delivered === false) {
        return <i className="fas fa-times"></i>;
      } else {
        return <i className="far fa-clock"></i>;
      }
    }
  }

  if (argument.type === "REQUEST_MEDIATOR") {
    return (
      <div className="others argument">
        <p className="date">{formatDate(argument.created_at)}</p>
        <p>{getName()} have requested for a mediator.</p>
        <p>A mediator will be assigned to your case shortly.</p>
        <p>Your assigned mediator can view all chat and transaction history.</p>
      </div>
    );
  }

  if (argument.type === "APPEAL_CASE") {
    return (
      <div className="others argument">
        <p className="date">{formatDate(argument.created_at)}</p>
        <p>
          {getName()} appealed this case - {argument.description}
        </p>
      </div>
    );
  }

  if (argument.type === "CLOSE_CASE") {
    return (
      <div className="others argument">
        <p className="date">{formatDate(argument.created_at)}</p>
        <p>
          {getName()} closed the case - {argument.description}
        </p>
      </div>
    );
  }

  return (
    <div lang="en-GB" className={classnames("argument", "chat", { sent })}>
      <div className="user-icon-container">
        <img src={require("../../assets/img/user.svg")} alt="user" />
      </div>

      <div lang="en-GB" className="body">
        <p className="date">{formatDate(argument.created_at)}</p>
        <p lang="en-GB" className="message">
          {argument.description}
        </p>
        {renderMessageIcon()}
      </div>
    </div>
  );
}

export default connect(({ auth }) => ({
  user: auth.user,
}))(Argument);
