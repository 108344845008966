import React from "react";
import classnames from "classnames";

function CardItem({ title, value }) {
  return (
    <div className="card-item">
      <p>{title}</p>
      <span>{value}</span>
    </div>
  );
}

export default function Card({ data, vissible }) {
  const { escrow, id } = data;

  return (
    <div className={classnames("dispute-details", { showing: vissible })}>
      {vissible ? (
        <>
          <CardItem title="Dispute ID" value={id} />
          <CardItem title="Description" value={escrow.description} />

          <div className="card-item">
            <p>Merchant Information</p>

            <div>
              <span>{escrow.recipient.name}</span>
              <span>{escrow.recipient.msisdn}</span>
            </div>
          </div>

          <div className="card-item">
            <p>Buyer Information</p>

            <div>
              <span>{escrow.sender.name}</span>
              <span>{escrow.sender.msisdn}</span>
            </div>
          </div>

          <CardItem title="Transaction Amount" value={"GHS " + escrow.amount} />
        </>
      ) : null}
    </div>
  );
}
