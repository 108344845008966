import React, { useState, useEffect } from "react";
import toast from "toasted-notes";
import classnames from "classnames";
import { connect } from "react-redux";

import OtpInput from "react-otp-input";
import "../../auth.css";

import { persistAuthState, verifyOTP } from "../../common";
import { login } from "../../redux/actions";
import { SET_PASSWORD, SIGN_UP } from "../../constants/routeNames";
import { Redirect } from "react-router-dom";

function VerifyOTP({ match, login, history }) {
  const [loading, setLoading] = useState(false);
  const [OTP, setOTP] = useState("");
  const { msisdn } = match.params;

  useEffect(() => {
    setTimeout(() => {
      const firstInput = document.getElementsByTagName("input")[0];

      if (firstInput) firstInput.focus();
    }, 100);
  }, []);

  async function submitForm(e) {
    e.preventDefault();

    if (!OTP)
      return toast.notify("Please enter the 4 Digit code sent to your Phone");

    setLoading(true);
    const res = await verifyOTP({ otp: OTP, msisdn });
    setLoading(false);

    if (res.code !== "00") return toast.notify(res.msg);

    persistAuthState(res);
    login(res.data);

    history.push(SET_PASSWORD);
  }

  if (!msisdn) return <Redirect to={SIGN_UP} />;

  return (
    <div id="verify-otp" className="auth">
      <form onSubmit={submitForm}>
        <div className="welcome-texts">
          <p>Enter the 4 digit code sent to your Phone</p>
        </div>

        <div>
          <div className="input-fields">
            <OtpInput
              containerStyle="otp-fields"
              value={OTP}
              onChange={(val) => setOTP(val)}
              numInputs={4}
              separator={<span>-</span>}
            />
          </div>

          <button
            disabled={loading}
            className={classnames("submit", { loading })}
          ></button>
        </div>
      </form>
    </div>
  );
}

export default connect(null, { login })(VerifyOTP);
