import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { createStore, applyMiddleware } from "redux";
import { Provider, connect } from "react-redux";
import thunk from "redux-thunk";
import { AnimatedRoute, AnimatedSwitch, spring } from "react-router-transition";
import "toasted-notes/src/styles.css";

import "./App.css";
import reducers from "./redux/reducers";
import {
  LOGIN,
  CHAT,
  SIGN_UP,
  VERIFY_OTP,
  HOME,
  SET_PASSWORD,
  FORGOT_PIN,
  LOGOUT,
} from "./constants/routeNames";
import Login from "./pages/auth/Login";
import SignUp from "./pages/auth/SignUp";
import VerifyOTP from "./pages/auth/VerifyOTP";
import ForgotPin from "./pages/auth/ForgotPin";
import SetNewPin from "./pages/auth/SetNewPin";
import Logout from "./pages/auth/Logout";
import Home from "./pages/Home";
import Chat from "./pages/chat";
import ProtectedRoute from "./hoc/ProtectedRoute";
import { getSocketInstance } from "./common";

const store = createStore(reducers, {}, applyMiddleware(thunk));

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

let App = ({ user }) => {
  useEffect(() => {
    if (!user) return;

    const io = getSocketInstance();

    io.on("connect", function () {
      console.log("WEBSOCKET CONNECTED");
    });

    return () => io.disconnect();
  }, [user]);

  return (
    <div id="main-content">
      <Router>
        <AnimatedSwitch
          atEnter={bounceTransition.atEnter}
          atLeave={bounceTransition.atLeave}
          atActive={bounceTransition.atActive}
          mapStyles={mapStyles}
        >
          <ProtectedRoute exact path="/" component={Home} />

          <Route exact path={LOGIN} component={Login} />
          <Route exact path={SIGN_UP} component={SignUp} />
          <Route exact path={VERIFY_OTP} component={VerifyOTP} />
          <Route exact path={FORGOT_PIN} component={ForgotPin} />
          <Route exact path={LOGOUT} component={Logout} />
          <ProtectedRoute exact path={SET_PASSWORD} component={SetNewPin} />

          <AnimatedRoute
            exact
            path={CHAT}
            render={() => <ProtectedRoute component={Chat} />}
            atEnter={{ offset: -100 }}
            atLeave={{ offset: -100 }}
            atActive={{ offset: 0 }}
            mapStyles={(styles) => ({
              transform: `translateX(${styles.offset}%)`,
            })}
          />

          {/* Redirect to the Root route if there's no matching route */}
          <Redirect to={HOME} />
        </AnimatedSwitch>
      </Router>
    </div>
  );
};

function mapStateToProps({ auth }) {
  return { user: auth.user };
}

App = connect(mapStateToProps)(App);

export default function () {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}
