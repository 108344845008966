import {
  LOGIN,
  FETCH_DISPUTES,
  FETCH_DISPUTE_STATS,
  CLOSE_CASE,
  REQUEST_MEDIATOR,
  APPEAL_CASE,
  SET_LOADING,
  ADD_ARGUMENT,
  SET_ARGUMENT_DELIVERED,
  LOGOUT,
  SET_DISPUTES_ERROR,
} from "../types";
import api from "../../api";
import { makeRequest } from "../../common";

export function login(user) {
  return { payload: user, type: LOGIN };
}

export function fetchDisputes(params = {}) {
  return async function (dispatch) {
    dispatch({ type: SET_LOADING, payload: true });

    const res = await makeRequest({ url: "/disputes/", method: "get", params });

    dispatch({ type: SET_LOADING, payload: false });

    if (res.code === "00") dispatch({ type: FETCH_DISPUTES, payload: res });

    // if the request did not fail, then just set the error message to null so that any previous error message will be cleared
    dispatch({
      type: SET_DISPUTES_ERROR,
      payload: res.code === "00" ? null : res.msg,
    });
  };
}

export function fetchDisputesStats() {
  return function (dispatch) {
    api
      .get("/disputes/stats")
      .then(({ data }) => {
        if (data.code === "00") {
          dispatch({ type: FETCH_DISPUTE_STATS, payload: data.data });
        }
      })
      .catch((err) => {
        console.log("STATS ERROR", err);
      });
  };
}

// function modifyDispute(disputeId, args) {
//   return api.post(`/disputes/${disputeId}/add-argument`, { ...args });
// }

export function closeDispute(disputeId) {
  return { type: CLOSE_CASE, payload: disputeId };
}

export function requestMediator(disputeId) {
  return { type: REQUEST_MEDIATOR, payload: disputeId };
}

export function appealCase(disputeId, msg) {
  return { type: APPEAL_CASE, payload: disputeId };
}

export function addArgument(payload) {
  return { type: ADD_ARGUMENT, payload };
}

export function setArgumentDeliveredStatus(payload) {
  return { type: SET_ARGUMENT_DELIVERED, payload };
}

export function logout() {
  window.localStorage.clear();
  return { type: LOGOUT };
}
